<script>
import { FormModel, Button, Icon, Row, Col } from 'ant-design-vue'
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    labelCol: {
      type: Object,
      default: () => {},
    },
    wrapperCol: {
      type: Object,
      default: () => {},
    },
    layout: {
      type: String,
    },
    typeData: {
      type: Object,
      default() {
        return {}
      },
    },
    mode: {
      type: String,
      default: 'create',
    },
    foot: {
      type: Function,
      default: () => null,
    },
    cols: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      formData: this.form || {},
    }
  },
  watch: {
    form(newValue) {
      this.formData = newValue
      // this.$refs._form.validate()
    },
  },
  methods: {
    getValue(item) {
      return item.value ? item.value : item.key ? this.formData[item.key] : null
    },
    onChange(key, value) {
      this.$set(this.formData, key, value)
      this.$forceUpdate()
    },
    onSelectChange(key, value) {
      this.$set(this.formData, key, value)
      this.$emit('selectChange')
      this.$forceUpdate()
    },
    getType() {
      return typeof this.typeData == 'function' ? this.typeData() : this.typeData
    },
    getTypeData(item) {
      const type = this.getType()
      return type[item.key] ? type[item.key] : item.typeData
    },
    getRulePattern(ruleType) {
      return {
        // 身份证
        idCard:
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
        // 手机号
        phone: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        // 邮箱
        email: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
      }[ruleType]
    },
    getKeysValidator(item, callback) {
      for (let i = 0; i < item.keys.length; i++) {
        const data = item.keys[i]
        const value = this.form[data.key]
        if (!value) {
          callback(data.message || '请输入' + item.label || item.name)
          continue
        }
      }
      callback()
    },
    getRules(rules, item) {
      if (item.keys) {
        return [
          {
            validator: (rule, value, callback) => {
              this.getKeysValidator(item, callback)
              callback()
            },
          },
        ]
      }
      return (rules || []).map((e) => {
        return {
          ...e,
          message: e.message || '请输入' + (item.name || item.label),
          pattern: e.ruleType ? this.getRulePattern(e.ruleType) : null,
        }
      })
    },
    setGroupValue(item, value, index) {
      const arr = [].concat(this.formData[item.key])
      arr[index] = value
      this.$set(this.formData, item.key + index, value)
      this.$set(this.formData, item.key, arr)
    },
    onDeleteGroupItem(item, index) {
      const arr = [].concat(this.formData[item.key])
      arr.splice(index, 1)
      this.$set(this.formData, item.key, arr)
    },
    renderGroupItemView({ item, value, index }) {
      return (Component) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '10px',
            }}
          >
            <FormModel.Item
              prop={item.key + index}
              label-col={item.labelCol}
              wrapper-col={item.wrapperCol}
              labelAlign={item.labelAlign || 'right'}
              style={{ minWidth: '200px' }}
              required={(item.rules || []).some((e) => e.required)}
              rules={this.getRules(item.rules, item)}
              getValue={this.getValue}
              setValue={this.onChange}
              trigger={['change', 'blur']}
            >
              <Component
                item={item}
                value={value}
                typeData={this.getTypeData(item)}
                onChange={(value) => this.setGroupValue(item, value, index)}
                mode={this.mode}
                formData={this.formData}
              />
            </FormModel.Item>
            {this.mode !== 'detail' && (
              <Icon
                type="minus-circle-o"
                style={{ fontSize: '24px', marginLeft: '10px' }}
                onClick={() => this.onDeleteGroupItem(item, index)}
              />
            )}
          </div>
        )
      }
    },
    getCols(item) {
      if (item.cols) return { span: item.cols, ...item.colsType }
      if (this.cols) return { span: this.cols }
      if (this.layout == 'horizontal') {
        return { span: item.cols || 24 }
      } else {
        return {
          md: 12,
          lg: 8,
          xl: 8,
          xxl: 8,
          span: item.cols || this.col,
        }
      }
    },
    renderComponent(item) {
      return (Component) => {
        return (
          <Col props={this.getCols(item)} offset={0} key={item.key}>
            <FormModel.Item
              label={item.name}
              prop={item.key}
              labelAlign={item.labelAlign || 'right'}
              label-col={item.labelCol}
              wrapper-col={item.wrapperCol}
              style={{ width: '100%', marginBottom: '10px' }}
              required={(item.rules || []).some((e) => e.required)}
              rules={this.getRules(item.rules, item)}
            >
              {item.head && item.head()}
              <Component
                item={item}
                value={this.getValue(item)}
                typeData={this.getTypeData(item)}
                onChange={(value) => this.onChange(item.key, value)}
                onChangeSelect={(value) => this.onSelectChange(item.key, value)}
                getValue={this.getValue}
                setValue={this.onChange}
                mode={this.mode}
                formData={this.formData}
              />
              {item.foot && item.foot()}
            </FormModel.Item>
          </Col>
        )
      }
    },
    onGroupDown(item) {
      if (this.formData[item.key] && Array.isArray(this.getValue(item))) {
        const arr = this.formData[item.key]
        arr.push('')
        this.$set(this.formData, item.key, arr)
      } else {
        this.$set(this.formData, item.key, [''])
      }
    },
    renderGroupItem(item) {
      return (Component) => {
        return (
          <FormModel.Item
            label={item.name}
            label-col={item.labelCol}
            wrapper-col={item.wrapperCol}
            style={{ minWidth: '200px' }}
            required={(item.rules || []).some((e) => e.required)}
            rules={this.getRules(item.rules, item)}
          >
            {(this.getValue(item) || []).map((e, i) => {
              return this.renderGroupItemView({ item, value: e, index: i })(Component)
            })}
            {this.mode !== 'detail' && (
              <Button block={true} onClick={() => this.onGroupDown(item)}>
                点击添加
              </Button>
            )}
          </FormModel.Item>
        )
      }
    },
    renderRow(item) {
      const Components = () => <Row gutter={item.gutter}>{item.children.map((e) => this.renderItem(e))}</Row>
      if (item.name) {
        return this.renderComponent(item)(Components)
      } else {
        return (
          <Col span={item.cols} align="left">
            <Row gutter={item.gutter}>{item.children.map((e) => this.renderItem(e))}</Row>
          </Col>
        )
      }
    },
    renderHidden(item) {
      return <Col props={this.getCols(item)}></Col>
    },
    renderButtonRight(item) {
      return (
        <Button style={item.style} type={item.props.type} icon={item.props.icon} onClick={() => item.onClick()}>
          {item.label}
        </Button>
      )
    },
    renderItem(item) {
      try {
        if (!item) return null
        if (item.type == 'row') return this.renderRow(item)
        if (item.hidden) return this.renderHidden(item)
        const name = item.type.slice(0, 1).toUpperCase() + item.type.slice(1)
        const Component = require(`./Form${name}`)
        if (item.group) {
          return this.renderGroupItem(item)(Component.default)
        } else {
          return this.renderComponent(item)(Component.default)
        }
      } catch (e) {
        console.log('报错提交', e, item)
        return this.renderComponent(item)(null)
      }
    },
    sumbit() {
      return new Promise((resolve) => {
        this.$refs._form.validate((error, data) => {
          if (!error) {
            this.$emit('error', data)
            resolve(false)
          } else {
            resolve(this.formData)
            this.$emit('success', this.formData)
          }
        })
      })
    },
    reset() {
      this.$refs._form.resetFields()
      this.formData = {}
    },
    getData() {
      return (this.data || []).filter((e) => {
        if (e == null) {
          return false
        } else {
          return e.display != undefined ? e.display : true
        }
      })
    },
  },
  render() {
    return (
      <FormModel
        props={{ model: this.formData }}
        label-col={this.labelCol}
        wrapper-col={this.wrapperCol}
        layout={this.layout}
        ref={'_form'}
      >
        <Row gutter={10} justify={'start'} type={'flex'}>
          {this.getData().map((e) => this.renderItem(e))}
          {this.$slots.default}
        </Row>
        {this.foot && this.foot()}
      </FormModel>
    )
  },
}
</script>
