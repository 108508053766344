<script>
import { Checkbox, Button } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array | String,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCheckItem(item) {
      const value = this.getValue()
      const setArr = new Set(value)
      if (setArr.has(item.value)) {
        setArr.delete(item.value)
      } else {
        setArr.add(item.value)
      }
      this.$emit('change', Array.from(setArr).toString())
    },
    renderButton(item) {
      return (
        <Button style={{ marginRight: '10px', marginTop: '3px' }}>
          <Checkbox value={item.value} onClick={() => this.onCheckItem(item)}>
            {item.name}
          </Checkbox>
        </Button>
      )
    },
    getValue() {
      if (typeof this.value === 'string') {
        return this.value.split(',').filter((e) => e)
      } else {
        return this.value
      }
    },
  },
  render() {
    return (
      <Checkbox.Group disabled={this.disabled} value={this.getValue()} style={{ display: 'flex', height: '100%' }}>
        {(this.typeData || []).map((e) => this.renderButton(e))}
      </Checkbox.Group>
    )
  },
}
</script>
