<!--
 * @Description: 
 * @Date: 2021-05-09 18:13:17
 * @LastEditors: tonglin.li
 * @LastEditTime: 2021-05-10 17:49:47
 * @FilePath: \ztesa-zsnc-admin\src\components\FormView\FormLabelGroup\index.vue
-->
<script>
import { Button } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    typeData: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    addorRemove(item, index) {
      const value = new Set(this.getValue())
      if (value.has(item.value)) {
        value.delete(item.value)
      } else {
        value.add(item.value)
      }
      this.$emit('change', Array.from(value).join(','))
    },
    getValue() {
      return new Set(((this.value || '').split(',') || []).filter((e) => e))
    },
    renderLabelItem(item, index) {
      const value = this.getValue()
      return (
        <div class="label-group-item" key={item.value}>
          <Button
            onClick={() => this.addorRemove(item, index)}
            class="label-button"
            type={value.has(item.value) ? 'primary' : 'default'}
          >
            {item.name}
          </Button>
        </div>
      )
    },
  },
  render() {
    return (
      <div class="label-group-main">
        {this.typeData.map((e, index) => {
          return this.renderLabelItem(e, index)
        })}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.label-group-main {
  display: flex;
  flex-wrap: wrap;
}
.label-group-item {
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
</style>
