<script>
import { TimePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: moment('00:00:00', 'HH:mm')
    }
  },

  render() {
    return (
      <TimePicker
        onChange={data => this.$emit('change', data)}
        props={this.item.props}
        {...this.item.props}
        value={this.value}
        style={{ width: '100%' }}
      />
    )
  }
}
</script>
