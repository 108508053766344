<script>
import { DatePicker } from 'ant-design-vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
function disabledDate(current) {
  // Can not select days before today and today
  // return current && current < moment().endOf('day');

  // 时间前一天不可选
  return current && current < moment().subtract(1, 'day')
}
export default {
  props: ['value', 'mode', 'item'],
  render() {
    return (
      <DatePicker
        disabledDate={this.item.disabledDate && disabledDate}
        props={this.item.props}
        style={{ width: '100%' }}
        disabled={this.mode == 'detail'}
        value={this.value}
        locale={locale}
        onChange={value => {
          if (this.item.props && this.item.props.showTime) {
            this.$emit('change', moment(value).format('YYYY-MM-DD HH:mm:ss'))
          } else {
            this.$emit('change', moment(value).format('YYYY-MM-DD'))
          }
        }}
      />
    )
  }
}
</script>
