<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :media-query="query"
    :is-mobile="isMobile"
    :handle-media-query="handleMediaQuery"
    :handle-collapse="handleCollapse"
    :i18n-render="i18nRender"
    v-bind="settings"
    :collapsedButtonRender="collapsedButtonRender"
  >
    <template v-slot:menuHeaderRender>
      <img v-if="collapsed" src="./asseet/small.png" />
      <img v-if="!collapsed" data-collapsed="false" src="./asseet/big.png" />
    </template>

    <template v-slot:headerContentRender>
      <div class="header-left">
        <a-tooltip title="刷新页面">
          <a-icon type="reload" style="font-size: 16px; cursor: pointer" @click="reloadPage" />
        </a-tooltip>
        <a-breadcrumb v-if="showBread == true && !isMobile" :routes="routes" style="margin-left: 20px" />
      </div>
    </template>

    <!-- 右边内容区  登录用户信息 -->
    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </template>
    <!-- custom footer / 自定义Footer -->
    <template v-slot:footerRender>
      <div></div>
    </template>
    <div>
      <multi-tab />
      <transition name="page-transition">
        <div style="margin-top: -20px">
          <router-view />
        </div>
      </transition>
    </div>
  </pro-layout>
</template>

<script>
import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'
import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import { asyncRouterMap } from '@/config/router.config.js'
import Ads from '@/components/Other/CarbonAds'
import { getAction } from '@/command/netTool'
import { BasicLayout } from '@/layouts'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}

export default {
  name: 'basic-layout',
  components: {
    SettingDrawer,
    RightContent,
    Ads,
  },
  data() {
    return {
      // 用于控制是否显示面包屑
      showBread: true,
      // 面包屑路由
      routes: [],
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end
      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false,
      },
      // 媒体查询
      query: {},
      // 是否手机模式
      isMobile: false,
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
    }),
  },
  created() {
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
    this.$watch('$route', () => {
      this.initBreadRouters()
    })
    window.addEventListener('resize', () => {
      if (document.body.clientWidth < 912) {
        this.collapsed = true
      } else {
        if (document.body.clientWidth < 1000) {
          this.showBread = false
        } else {
          if (this.showBread == false) {
            this.showBread = true
          }
        }
      }
    })

    //**************************************** */

    // if (process.env.NODE_ENV === 'development') {
    //   const routes = asyncRouterMap.find((item) => item.path === '/')
    //   this.menus = (routes && routes.children) || []
    //   this.getQiniuToken()
    // } else {
    //   this.getQiniuToken()
    //   this.getMenuNumber()
    // }
    // this.getQiniuToken()
    // this.getMenuNumber()
    this.getQiniuToken()
    this.getMenuNumber()
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }
  },
  methods: {
    // 获取七牛token
    getQiniuToken() {
      getAction('/api/gxlkoperation/common/getQnToken').then((e) => {
        localStorage.setItem('qiniu', JSON.stringify(e))
      })
    },
    // 获取菜单数量
    getMenuNumber() {
      //   getAction('/api/base/menuCount/countAllRecord').then(e => {
      //     this.getMenu(e.data)
      //   })
      this.getMenu()
    },
    i18nRender,
    getComponent(item) {
      if (item.component == 'RouteView') {
        return RouteView
      } else if (item.component == 'BasicLayout') {
        return BasicLayout
      } else if (item.components && e.children.length == 0) {
        return null
      } else {
        return () => import(`@/views/${item.path}`)
      }
    },
    getSvgInit() {
      let arr = require.context('../assets/icons/', true, /\.svg$/).keys()
      let obj = {}
      arr.forEach((e) => {
        let a = e.substring(e.indexOf('./') + 2, e.indexOf('.svg'))
        obj[a] = require(`@/assets/icons/${a}.svg?inline`).default
      })
      return obj
    },
    getChildren(children = [], obj, objSvg, button, path) {
      const mChildren = children.filter((e) => e.type !== '1')
      const mButton = children.filter((e) => e.type == '1')
      if (path && path[0] != '/') {
        path = '/' + path
      }
      if (mButton.length > 0) {
        button[path] = mButton
      }
      if (mChildren.length > 0) {
        // const numObj = {
        //   orderProcessing: obj.totalOrder > 0 ? obj.totalOrder : '',
        //   localProducts: obj.order > 0 ? obj.order : '',
        //   refund: obj.refund > 0 ? obj.refund : '',
        //   feedback: obj.feedback > 0 ? obj.feedback : '',
        //   audit: obj.totalAudit > 0 ? obj.totalAudit : '',
        //   settlementAudit: obj.settlement > 0 ? obj.settlement : ''
        // }
        return mChildren.map((e) => {
          return {
            ...e,
            name: e.title,
            component: this.getComponent(e),
            hidden: e.display !== '0' ? true : false,
            meta: {
              title: e.name,
              subTitle: e.subtitle,
              keepAlive: e.keepAlive == '0' ? true : false,
              icon: objSvg[e.icon],
              //   num: numObj[e.title]
            },
            children: this.getChildren(e.children, obj, objSvg, button, e.path),
          }
        })
      } else {
        return null
      }
    },
    getMenu(obj) {
      getAction('/api/admin/user/info', {})
      getAction('/api/admin/menu/userTree?sysCode=gxlk', {}).then((e) => {
        const button = {}
        this.menus = this.getChildren(e, obj, this.getSvgInit(), button)
        const routes = asyncRouterMap.find((item) => item.path === '/')
        this.menus = (routes && routes.children) || []
        localStorage.setItem('button', JSON.stringify(button))
      })
    },
    onMove() {
      if (this.collapsed) {
        this.collapsed = false
      }
    },
    listenMove() {
      if (document.body.clientWidth > 660) {
        // document.getElementsByClassName('ant-layout-sider-children')[0].removeEventListener('mousemove',this.onMove)
        // document.getElementsByClassName('ant-layout-sider-children')[0].addEventListener('mousemove', this.onMove)
      }
    },
    reloadPage() {
      this.$router.go(0)
    },
    initBreadRouters() {
      this.routes = this.$route.matched.map((e) => ({
        path: e.path,
        breadcrumbName: e.meta.title,
      }))
    },
    handleMediaQuery(val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSettingChange({ type, value }) {
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    },
    collapsedButtonRender(h, collapsed) {
      if (collapsed) {
        return (
          <div>
            <span
              class="iconfont icon-mengbanzu3"
              style={{ fontSize: '16px', fontWeight: '900', color: '#000' }}
            ></span>
          </div>
        )
      } else {
        return (
          <div>
            <span
              class="iconfont icon-mengbanzu3-copy"
              style={{ fontSize: '16px', fontWeight: '900', color: '#000' }}
            ></span>
          </div>
        )
      }
    },
  },
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>
<style lang="less" scoped>
.header-left {
  display: flex;
  align-items: center;
  height: 100%;
}
.pro-layout-ui {
  background: red;
}
.logo_box {
  width: 89%;
  height: auto;
  margin-left: -4%;
}
.logo_box2 {
  height: auto;
}
/deep/.ant-breadcrumb {
  span {
    &:nth-last-child(1) {
      .ant-breadcrumb-link {
        font-weight: 600;
      }
    }
  }
}
.layout-header {
  display: flex;
  align-items: center;
  height: 100%;
  & > img[data-collapsed='false'] {
    width: 100%;
    height: 100%;
  }
}
/deep/.ant-menu-vertical {
  img {
    width: 20px !important;
    height: 20px !important;
  }
  .ant-menu-submenu-title {
    & > div > span {
      & > span:nth-child(2) {
        display: none;
      }
    }
  }
  .slider-menu-subTitle {
    display: none;
  }
  a > span:nth-child(2) {
    display: none;
  }
}
/deep/.ant-pro-sider-menu-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px !important;
  a {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/.ant-layout-sider-collapsed {
  .ant-pro-sider-menu-logo {
    transition: all 0.5s;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 30px;
      height: 30px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
