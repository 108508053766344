<script>
import { InputNumber } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: null
    }
  },
  render() {
    return (
      <InputNumber
        props={this.item.props}
        disabled={this.mode == 'detail'}
        value={this.value}
        placeholder={
          this.item.placeholder
            ? this.item.placeholder
            : '请输入' + (this.item.name || this.item.label)
        }
        onChange={e => this.$emit('change', e)}
        style={{ width: '100%' }}
      />
    )
  }
}
</script>
