<script>
import { Dropdown, Menu, Select, Button } from 'ant-design-vue'
import debounce from 'lodash/debounce'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: () => {}
    },
    typeData: {
      type: Array,
      default: () => []
    },
    getValue: {
      type: Function,
      default: null
    },
    setValue: {
      type: Function,
      default: null
    }
  },
  mounted() {
    this.fetchUser = debounce(this.onInput, 800)
  },
  methods: {
    onInput(value) {
      if (this.item.onInputSearch) {
        this.item.onInputSearch(value)
      }
    },
    onChange(data) {
      this.setValue(this.item.valueKey, data)
    }
  },
  render() {
    return (
      <div style={{ marginTop: '4px' }}>
        <div class="drop-down-main">
          <Select
            mode="multiple"
            placeholder="请选择店铺"
            style="width: 100%"
            show-search={true}
            filter-option={false}
            onSearch={this.onInput}
            onChange={this.onChange}
            value={this.getValue({ key: this.item.valueKey })}
          >
            {this.typeData.map(e => {
              return <Select.Option value={e.value}>{e.name}</Select.Option>
            })}
          </Select>
        </div>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.drop-down-main {
  display: flex;
  align-items: center;
  width: 100%;
  [type='button'] {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 2px;
    margin-left: -1px;
    width: 50px;
  }
}
</style>
