<template>
  <div id="warrper">
    <a-select
      v-if="tenantArr.length > 0"
      v-model="tenantValue"
      class="select_box"
      style="width: 120px"
      @change="handleChangeSelect"
    >
      <template v-for="(item, index) in tenantArr">
        <a-select-option :key="index" :value="item.countryId">
          {{ item.countryName }}
        </a-select-option>
      </template>
    </a-select>
    <!-- 右上角登录信息的下拉菜单配置 -->
    <a-dropdown
      v-if="currentUser && currentUser.name"
      placement="bottomRight"
      style="width:150px;"
    >
      <span class="ant-pro-account-avatar">
        <a-avatar
          size="small"
          :src="currentUser.avatar"
          class="antd-pro-global-header-index-avatar"
        />
        <!-- 当前的用户名 -->
        <span>{{ currentUser.name }}</span>
      </span>
      <template v-slot:overlay>
        <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
          <!-- 设置 -->
          <!-- <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          {{ $t('menu.account.center') }}
        </a-menu-item>
        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          {{ $t('menu.account.settings') }}
        </a-menu-item> -->
          <!-- <a-menu-divider v-if="menu" /> -->
          <!-- 退出登录 -->
          <a-menu-item key="logout" @click="handleLogout">
            <a-icon type="logout" />
            退出登录
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
    </span>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { getAction } from '@/command/netTool'

export default {
  name: 'avatar-dropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tenantArr: [],
      tenantValue: null
    }
  },
  mounted() {
  },
  methods: {
    handleChangeSelect(e) {
      let that = this
      localStorage.setItem(
        'countryCode',
        this.tenantArr.find(el => el.countryId == e).countryCode
      )
      localStorage.setItem('countryId', e)
      getAction('/fc-operation/countryInfo/get', {}, '/bpi').then(e => {
        localStorage.setItem('countryInfo', JSON.stringify(e.data))
        setTimeout(() => {
          that.$nextTick(() => {
            that.$router.go(0)
          })
        }, 150)
      })
    },
    handleToCenter() {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout(e) {
      Modal.confirm({
        title: this.$t('layouts.usermenu.dialog.title'),
        content: '您确定要退出吗？',
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
#warrper {
  display: flex;
  align-items: center;
}
.select_box {
  margin-right: 15px;

  /deep/.ant-select-selection {
    border: none;
  }
}
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: initial;
  }
}

.ant-avatar-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
}
</style>
