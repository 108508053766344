<script>
import { Cascader, Row, Col, Input } from 'ant-design-vue'
import { getAction } from '../../../command/netTool'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default {
  props: {
    setValue: {
      type: Function,
      default: null,
    },
    getValue: {
      type: Function,
    },
  },
  data() {
    return { options: [] }
  },
  mounted() {
    getAction('/api/currency/region/freeTree?level=4&id=330200').then((e) => {
      this.options = e
    })
  },
  methods: {
    getCascaderValue() {
      const areaCode = this.getValue({ key: 'areaCode' }) || ''
      const streetCode = this.getValue({ key: 'streetCode' }) || ''
      return [areaCode, streetCode].filter((e) => e)
    },
  },
  render() {
    return (
      <div class="address">
        <Row gutter={10}>
          <Col span={6}>
            <Cascader
              placeholder="请选择数据"
              options={this.options}
              fieldNames={{ label: 'name', value: 'id', children: 'children' }}
              value={this.getCascaderValue()}
              onChange={(e, selectedOptions) => {
                this.setValue('areaCode', selectedOptions[0].id)
                this.setValue('streetCode', selectedOptions[1].id)
                this.setValue('areaName', selectedOptions[0].name)
                this.setValue('streetName', selectedOptions[1].name)
              }}
            />
          </Col>
          <Col span={18}>
            <Input
              placeholder="请输入内容"
              value={this.getValue({ key: 'address' })}
              onChange={(e) => this.setValue('address', e.target.value)}
            />
          </Col>
        </Row>
      </div>
    )
  },
}
</script>
