<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['item', 'value', 'mode'],
  render() {
    return (
      <div class="form-input">
        <audio controls="controls">
          <source src={this.value} type="audio/ogg" />
        </audio>
      </div>
    )
  }
}
</script>
<style lang="less" scoped></style>
