<script>
import { Switch } from 'ant-design-vue'
export default {
  props: ['value', 'mode'],
  mounted() {
    if (this.value == null) {
      this.$emit('change', '1')
    }
  },
  render() {
    return (
      <Switch
        disabled={this.mode == 'detail'}
        checked={this.value == '1'}
        defaultChecked={this.value == '1'}
        onChange={data => {
          this.$emit('change', data ? '1' : '0')
        }}
      />
    )
  }
}
</script>
