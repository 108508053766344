<script>
import TableVIew from '../../TableVIew'
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  render() {
    return (
      <TableVIew props={this.item} scrollX={'auto'} showRowSelect={false} />
    )
  }
}
</script>
