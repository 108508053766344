<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.map = new AMap.Map(this.$refs.map, {
      resizeEnable: true,
      viewMode: '3D',
      zoom: 0
    })
    const markerList = this.value.map(
      item =>
        // eslint-disable-next-line no-undef
        new AMap.Marker({
          position: [item.lng, item.lat]
        })
    )
    this.map.add(markerList)
  },
  render() {
    return (
      <div class="mapview-main">
        <div ref="map" style={{ display: 'flex', height: '300px' }} />
      </div>
    )
  }
}
</script>
