<script>
import { Row, Col, TimePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  props: {
    item: {
      type: Object,
    },
    getValue: {
      type: Function,
    },
    setValue: {
      type: Function,
    },
  },
  methods: {
    getTimeText(key) {
      const value = this.getValue({ key })
      if (value) {
        return moment(value, 'HH:mm:ss')
      } else {
        return ''
      }
    },
  },
  render() {
    const data = [
      {
        name: '开始时间',
      },
      {
        name: '结束时间',
      },
    ]
    return (
      <div class="time-range-picker">
        <Row gutter={10}>
          {this.item.keys.map((e, i) => {
            return (
              <Col span={12}>
                <TimePicker
                  value={this.getTimeText(e.key)}
                  style={{ width: '100%' }}
                  placeholder={'请选择' + e.name}
                  onChange={(value) => {
                    if (value == null) {
                      this.setValue(e.key, '')
                    } else {
                      this.setValue(e.key, moment(value).format('HH:mm:ss'))
                    }
                  }}
                />
              </Col>
            )
          })}
        </Row>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.time-range-picker {
  display: flex;
  align-items: center;
  // margin-top: 4px;
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
