<script>
import { Checkbox } from 'ant-design-vue'
export default {
  props: ['typeData', 'value'],
  render() {
    return (
      <Checkbox.Group
        value={this.value}
        onChange={value => this.$emit('change', value)}
        options={(this.typeData || []).map(e => ({
          label: e.name,
          value: e.value
        }))}
      ></Checkbox.Group>
    )
  }
}
</script>
