<script>
import { Tabs } from 'ant-design-vue'
import FormView from '../index.vue'
export default {
  props: {
    item: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: '0'
    }
  },
  render() {
    return (
      <Tabs v-model={this.current}>
        {
          this.item.data.map((item, i) => {
            return (
              <Tabs.TabPane tab={item.title} key={String(i)}>
                <FormView data={item.children} cols={24} />
              </Tabs.TabPane>
            )
          })
        }
      </Tabs>
    )
  }
}
</script>