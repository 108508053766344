// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import vcolorpicker from 'vcolorpicker'
import { message } from 'ant-design-vue'
Vue.use(vcolorpicker)

message.config({
  maxCount: 1,
})
import * as fundebug from 'fundebug-javascript'
import fundebugVue from 'fundebug-vue'
fundebug.apikey = 'd5bb08d7a5062f776d143db32e8242b5a0b76c0ce144dbddb69a44de4267076b'
fundebugVue(fundebug, Vue)
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import './assets/fonts/iconfont.css'

// mount axios to `Vue.$http` and `this.$http`
// Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: (h) => h(App),
}).$mount('#app')
