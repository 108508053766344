<template>
  <div>
    <color-picker v-model="color" @change="headleChangeColor" />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    setValue: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      color: '#e1e1e1'
    }
  },
  watch: {
    'item.defaultColorValue'(a, b) {
      if (a) {
        this.color = a
      }
    }
  },
  created() {
    this.color = this.item.defaultColorValue
    this.$forceUpdate()
  },
  methods: {
    headleChangeColor(e) {
      this.setValue(this.item.target, e)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.m-colorPicker .box {
  left: -197px;
}
</style>
