<script>
import { Input } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: {}
    },
    getValue: {
      type: Function,
      default: null
    },
    setValue: {
      type: Function,
      default: null
    }
  },
  render() {
    return (
      <div class="input-group">
        {
          this.item.datas.map((e, i) => {
            return (
              <div>
                <Input
                  placeholder={e.placeholder}
                  value={this.getValue(e)}
                  onChange={(event) => {
                    this.setValue(e.key, event.target.value)
                  }}
                />
                {i == 0 && <span>-</span>}
              </div>
            )
          })
        }
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
    span {
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>