<script>
import { Input } from 'ant-design-vue'
export default {
  props: ['value'],
  data() {
    return {
      lnglat: ''
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.map = new AMap.Map(this.$refs.map, {
      resizeEnable: true,
      zoom: 11,
      center: [121.6216, 29.859515],
      viewMode: '3D'
    })
    let that = this
    // eslint-disable-next-line no-undef
    this.clickListener = AMap.event.addListener(this.map, 'click', function(e) {
      that.addMarker(e.lnglat)
    })
    // eslint-disable-next-line no-undef
    let autoCompleteComponent = new AMap.Autocomplete({
      input: 'tipinput'
      // outPutDirAuto: true
    })
    // eslint-disable-next-line no-undef
    AMap.event.addListener(autoCompleteComponent, 'select', data => {
      that.addMarker(data.poi.location)
    })
    const markerList = this.value.map(
      item =>
        // eslint-disable-next-line no-undef
        new AMap.Marker({
          position: [item.lng, item.lat]
        })
    )
    this.map.add(markerList)
  },
  methods: {
    addMarker(lnglat) {
      this.lnglat = lnglat
      this.map.clearMap()
      this.map.setCenter([lnglat.lng, lnglat.lat])
      this.map.add(
        // eslint-disable-next-line no-undef
        new AMap.Marker({
          position: [lnglat.lng, lnglat.lat]
        })
      )
    },
    getData() {
      return this.lnglat
    }
  },
  render() {
    return (
      <div class="mapview-main">
        <Input
          id="tipinput"
          enter-button="搜索"
          ref="input"
          placeholder="请输入要搜索地址"
        />
        <div ref="map" style={{ display: 'flex', height: '300px' }} />
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.mapview-main {
  display: flex;
  flex-direction: column;
  & > div {
    margin-top: 20px;
  }
}
</style>
