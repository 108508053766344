<script>
import events from './events'
import { Tabs, TabPane, Icon } from 'view-design'
export default {
  name: 'multi-tab',
  data() {
    return {
      fullPathList: [],
      pages: [],
      activeKey: '',
      newTabIndex: 0
    }
  },
  watch: {
    $route: function(newVal) {
      this.activeKey = newVal.fullPath
      if (this.fullPathList.indexOf(newVal.fullPath) < 0) {
        this.fullPathList.push(newVal.fullPath)
        this.pages.push(newVal)
      }
    },
    activeKey: function(newPathKey) {
      this.$router.push({ path: newPathKey })
    }
  },
  created() {
    // bind event
    events
      .$on('open', val => {
        if (!val) {
          throw new Error(`multi-tab: open tab ${val} err`)
        }
        this.activeKey = val
      })
      .$on('close', val => {
        if (!val) {
          this.closeThat(this.activeKey)
          return
        }
        this.closeThat(val)
      })
      .$on('rename', ({ key, name }) => {
        try {
          const item = this.pages.find(item => item.path === key)
          item.meta.customTitle = name
          this.$forceUpdate()
        } catch (e) {}
      })

    this.pages.push(this.$route)
    this.fullPathList.push(this.$route.fullPath)
    this.selectedLastPath()
  },
  methods: {
    onEdit(targetKey, action) {
      this[action](targetKey)
    },
    remove(targetKey) {
      this.pages = this.pages.filter(page => page.fullPath !== targetKey)
      this.fullPathList = this.fullPathList.filter(path => path !== targetKey)
      // 判断当前标签是否关闭，若关闭则跳转到最后一个还存在的标签页
      if (!this.fullPathList.includes(this.activeKey)) {
        this.selectedLastPath()
      }
    },
    selectedLastPath() {
      this.activeKey = this.fullPathList[this.fullPathList.length - 1]
    },

    // content menu
    closeThat(e) {
      // 判断是否为最后一个标签页，如果是最后一个，则无法被关闭
      if (this.fullPathList.length > 1) {
        this.remove(e)
      } else {
        this.$message.info('这是最后一个标签了, 无法被关闭')
      }
    },
    closeLeft(e) {
      const currentIndex = this.fullPathList.indexOf(e)
      if (currentIndex > 0) {
        this.fullPathList.forEach((item, index) => {
          if (index < currentIndex) {
            this.remove(item)
          }
        })
      } else {
        this.$message.info('左侧没有标签')
      }
    },
    closeRight(e) {
      const currentIndex = this.fullPathList.indexOf(e)
      if (currentIndex < this.fullPathList.length - 1) {
        this.fullPathList.forEach((item, index) => {
          if (index > currentIndex) {
            this.remove(item)
          }
        })
      } else {
        this.$message.info('右侧没有标签')
      }
    },
    closeAll(e) {
      const currentIndex = this.fullPathList.indexOf(e)
      this.fullPathList.forEach((item, index) => {
        if (index !== currentIndex) {
          this.remove(item)
        }
      })
      this.pages = [
        { meta: { title: '主页' }, fullPath: '/orderProcessing/localProducts' }
      ]
      this.fullPathList = ['/orderProcessing/localProducts']
      this.activeKey = '/orderProcessing/localProducts'
    },
    closeMenuClick(key, route) {
      this[key](route)
    },
    renderTabPaneMenu() {
      const menu = [
        {
          name: '关闭当前标签',
          key: 'closeThat'
        },
        {
          name: '关闭右侧',
          key: 'closeRight'
        },
        {
          name: '关闭左侧',
          key: 'closeLeft'
        },
        {
          name: '关闭全部',
          key: 'closeAll'
        }
      ]
      return (
        <a-menu
          {...{
            on: {
              click: ({ key, item, domEvent }) => {
                this.closeMenuClick(key, this.activeKey)
              }
            }
          }}
        >
          {menu.map(e => {
            return (
              <a-menu-item key={e.key} onClick={e.onClick}>
                {e.name}
              </a-menu-item>
            )
          })}
        </a-menu>
      )
    },
    // render
    renderTabPane(title, keyPath) {
      return () => {
        return (
          <div>
            {title}
            <a-icon
              type="close"
              style={{ fontSize: '12px', marginLeft: '10px' }}
              onClick={() => this.closeThat(keyPath)}
            />
          </div>
        )
      }
    },
    renderMenu() {
      const menu = this.renderTabPaneMenu()
      return (
        <a-dropdown overlay={menu} style={{ minWidth: '30px' }}>
          <Icon type="ios-arrow-down" class="header-menu-icon" />
        </a-dropdown>
      )
    }
  },
  render() {
    const {
      onEdit,
      $data: { pages }
    } = this
    const panes = pages.map(page => {
      return (
        <TabPane
          label={this.renderTabPane(
            page.meta.customTitle || page.meta.title,
            page.fullPath
          )}
          name={page.fullPath}
        ></TabPane>
      )
    })
    return (
      //   {this.renderMenu()}
      <div class="ant-pro-multi-tab">
        <div class="header-multi-tab">
          <Tabs type="card" draggable={false} v-model={this.activeKey}>
            {panes}
          </Tabs>
        </div>
      </div>
    )
  }
}
</script>
<style lang="less" scoped>
.header-multi-tab {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(238, 240, 243);
  padding-left: 22px;
  padding-right: 22px;
  /deep/.ivu-tabs-bar {
    border-bottom: none !important;
    margin-bottom: 0px !important;
  }
  /deep/.ivu-tabs-tab {
    border: none;
    background: white !important;
    border-radius: 3px;
    margin-right: 10px !important;
    height: 30px;
  }
  /deep/.ivu-tabs-tab {
    border: 1px solid #dcdee2 !important;
    border-radius: 4px !important;
  }
}
.header-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 32px;
  height: 32px;
}
</style>
