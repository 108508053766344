<script>
import { TreeSelect } from 'ant-design-vue'
import { getAction } from '@/command/netTool'

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeData: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nodeId: 1
    }
  },
  watch: {
    value(a, b) {
      if (a) {
        this.$emit('change', a)
      }
    },
    typeData(a, b) {
      console.log(a, b)
    }
  },
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.value || this.item.defaultValue)
    }
  },
  methods: {
    // genTreeNode(parentId) {
    //   return new Promise((resolve, reject) => {
    //     getAction(
    //       `/api/config/farmGoodsCategory/listForChild?nodeId=${parentId}`
    //     ).then(res => {
    //       if (res.code == 200) {
    //         let array = res.data
    //         let arr = array.map(e => {
    //           return {
    //             ...e,
    //             children: getChildren(e.childList),
    //             key: e.code,
    //             value: e.code,
    //             title: e.name
    //           }
    //         })
    //         console.log(arr, '==arr==')
    //         resolve(arr)
    //       } else {
    //         this.$message.warning(res.msg)
    //       }
    //     })
    //   })
    // },
    // processData(arr, nodeId, arrList, bArr = []) {
    //   try {
    //     arr.forEach(element => {
    //       if (element.nodeId == nodeId) {
    //         element.children = arrList
    //         throw new Error('结束')
    //       } else {
    //         console.log(element.children, '==element.children==')
    //         this.processData(element.children, nodeId, arrList, (bArr = []))
    //       }
    //     })
    //   } catch (error) {
    //     bArr = arr
    //     console.log(error, '==error,error==')
    //     console.log(bArr)
    //     return false
    //   }
    // },
    // onLoadData(treeNode) {
    //   let that = this
    //   return new Promise(resolve => {
    //     const { nodeId } = treeNode.dataRef
    //     let a = that.typeData
    //     let b = []
    //     setTimeout(() => {
    //       that.genTreeNode(nodeId).then(result => {
    //         that.processData(a, nodeId, result, b)
    //       })
    //       resolve()
    //     }, 300)
    //   })
    // }
  },
  render() {
    return (
      <TreeSelect
        v-model={this.value}
        style="width: 100%"
        tree-data={this.typeData}
        tree-checkable={this.item.isCheckable}
        search-placeholder="请选择分类"
        showSearch={true}
        treeNodeFilterProp="title"
        // load-data={this.onLoadData}
      />
    )
  }
}
</script>
