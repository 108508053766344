<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'blank-layout'
}
</script>

<style scoped></style>
